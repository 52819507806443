import React, { Component } from 'react'
import { Link } from 'gatsby'
import './upcoming-events-limited.css'

import styled from 'styled-components'
import Loader from 'react-loader-spinner'

//Parse Functions
import { GetLimitedPumps } from '../services/parse-bridge'

//Components
import PumpContainer from './pump-container'
import MyLoader from './loader'
import { SectionTitle } from '../style/typography'
import Button from './button'

const Spinner = styled.div`
  text-align: center;
  margin: 15px 0;
`
const SeeMoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-family: "Poppins";
    text-decoration: none;

  }
`

class UpcomingEventsLimited extends Component {
  state = {
    pumps: [],
    loading: true,
  }

  componentDidMount() {
    GetLimitedPumps().then(response => {
      this.setState({ pumps: response, loading: false })
    })
  }

  render() {
    return (
      <div className="UpcomingEvents">
        <SectionTitle>Upcoming events</SectionTitle>
        {this.state.loading && (
          <Spinner>
            <Loader type="Oval" color="#Fc444e" height="40" width="40" />
          </Spinner>
        )}
        <div className="pumpContainer">
          {this.state.pumps.map(pump => (
            <PumpContainer
              id={pump.id}
              key={pump.id}
              flyer={pump.flyer}
              name={pump.name}
              price={pump.price}
              location={pump.location}
              date={pump.date}
            />
          ))}
        </div>

        <SeeMoreContainer>
          <Button title="View all events" secondary link="/events" />
        </SeeMoreContainer>
      </div>
    )
  }
}

export default UpcomingEventsLimited
