import React, { Component } from 'react'
import './how-it-works.css'

//NPM
import { FaPlus, FaRegCreditCard, FaQrcode } from 'react-icons/fa'

class HowItWorks extends Component {
  render() {
    return (
      <div className="HowItWorks">
        <div id="howSections">
          <h1>How it works</h1>

          <div className="section1">
            <FaPlus />
            <h2>Create custom tickets</h2>
            <p>
              Using our dashboard, promoters are able to create tickets with
              varying types & pricing.*
            </p>
          </div>
          <div className="section2">
            <FaRegCreditCard />
            <h2>Users purchase tickets</h2>
            <p>
              The generated tickets then become available for users to purchase
              on our mobile app and website. Patrons pay online with Credit Card
              and promoters can even issue tickets to their committee to sell
              via cash.
            </p>
          </div>
          <div className="section3">
            <FaQrcode />
            <h2>A QR Code is generated</h2>
            <p>
              Once purchased, tickets are automatically delivered to the user's
              device and email address. Each ticket has fraud protection built
              in, allowing patrons to transfer and resell tickets safely. Our
              aim is to ensure convenience and peace of mind when it comes to
              events.
            </p>
          </div>
          <p className="disclaimer">
            *If you prefer, we can set up your tickets and manage your event for
            you.
          </p>
        </div>
      </div>
    )
  }
}

export default HowItWorks
