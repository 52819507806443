import React, { Component } from 'react';
import Loader from 'react-loader-spinner'

import './loader.css'

class MyLoader extends Component {
    render() {
        return (
            <div className="MyLoader">
                <Loader
                    type="MutatingDot"
                    color="#dddddd"
                    height="100"
                    width="100"
            />
            </div>
            
        );
    }
}

export default MyLoader;