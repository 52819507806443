import React, { Component } from 'react';
import { Link } from 'gatsby'
import Button from './button'
import TicketPhone from './ticket-phone-image'
import './home-announcement.css'

class HomeAnnouncement extends Component {
    render() {
        return (
            <div className="HomeAnnouncement">
                <div className="AnnouncementContainer">
                    <div>
                        <h1>Introudcing Digital Tickets</h1>
                        <p>save time with a lightning fast checkout experience</p>
                        <Link to="/tickets"><Button title="Learn More" secondary={true} /></Link>
                    </div>
                    <div>
                        <TicketPhone/>
                    </div>
                </div>

            </div>
        );
    }
}

export default HomeAnnouncement;