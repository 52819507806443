import React, { Component } from 'react'
import './pump-container.css'
import { Link } from 'gatsby'

import chev from '../images/right-chevron.png'

//NPM

class PumpContainer extends Component {
  state = {}
  render() {
    const link = `/events/${this.props.id}`
    return (
      <div className="PumpContainer">
        <Link to={link}>
          <div className="container">
            <div className="eventFlyer">
              <img src={this.props.flyer} />
            </div>
            <div className="detailsContainer">
              <div className="name">
                <p>{this.props.name}</p>
              </div>
              <div className="location">
                <p>
                  {this.props.location} | {this.props.date}
                </p>
              </div>
              {this.props.price === 1 ? (
                <p className="price">Pricing will be released soon</p>
              ) : (
                  <p className="price">Starting at {this.props.price}</p>
                )}
            </div>
            <div className="chevron">
              <img src={chev} />
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default PumpContainer
