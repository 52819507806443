import React, { Component } from 'react';
import './faqs.css'
import faqs from '../faqs.json'

class Faqs extends Component {

    render() {
        return (
            <div className="Faqs">
                
                <div className="faqsContainer">
                <h1>FAQs</h1>
                    {
                        faqs.faqs.map((faq, index) => (
                            <div key={index} className="questions">
                                <h1>{faq.title}</h1>
                                <p>{faq.answer}</p>
                            </div>
                        ))
                    }
                </div>

            </div>
        );
    }
}

export default Faqs;