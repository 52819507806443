import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

//Components
import TicketsHeader from '../components/tickets-header'
import AddEventCallout from '../components/add-event-callout'
import UpcomingEventsLimited from '../components/upcoming-events-limited';
import HomeAnnouncement from '../components/home-announcement';
import Footer from '../components/footer';
import HowItWorks from '../components/how-it-works';
import Pricing from '../components/pricing';
import Faqs from '../components/faqs';

const TicketsPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <TicketsHeader />
    <HowItWorks />
    <Pricing />
    <Faqs />
    <Footer />
  </Layout>
)

export default TicketsPage
