import React, { Component } from 'react'
import styled from 'styled-components'
import './pricing.css'

//Components
import Button from './button'

class Pricing extends Component {
  render() {
    return (
      <div className="Pricing">
        <h1>Our pricing is simple and transparent, always.</h1>
        {/* <h2>We help you avoid ticketing fees by passing them to attendees</h2> */}

        <div className="pricingContainer">
          <h3 className="pricingTitle">
            Everything you need to get started selling tickets for your event.
          </h3>
          <FeatureList>
            <Item>Up to 3 ticket types (eg. Early Bird, General, VIP)</Item>
            <Item>Create unlimited tickets</Item>
            <Item>Reporting Dashboard</Item>
            <Item>Access to our 'Promoter' app</Item>
          </FeatureList>

          <hr />
          <Button title="Create Tickets" primary={true} />
          <p className="pricingBody">For a ticket that costs $35USD</p>
          <hr />
          <h1>
            YOU PAY: <span className="money">$0.00</span> / ticket
          </h1>
          <h1>
            PATRONS: <span className="money">$38.50</span> / ticket
          </h1>
          <p className="disclaimer">
            *We keep a percentage of the convenience fee and the rest goes to
            our payment provider, Stripe.
          </p>
        </div>
      </div>
    )
  }
}

const FeatureList = styled.ul`
  margin: 0 auto;
  width: 90%;
  list-style-position: inside;
`
const Item = styled.li`
  margin: 0;
  font-size: 0.85rem;
`

export default Pricing
