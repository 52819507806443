import React from 'react'
import './tickets-header.css'

//Components
import TicketPhone from './ticket-phone-image'
import Button from './button'

class TicketsHeader extends React.Component {
  render() {
    return (
      <div className="TicketsHeader">
        <div className="TicketIntro">
          <div>
            <h1>
              A complete digital ticketing platform engineered for fun and ease
              of use
            </h1>
            <p>
              Let's help you create an event experience that's fun for both you
              and your patrons.
            </p>
          </div>
          <div>{/* <TicketPhone/> */}</div>
          <Button title="Get started" primary={true} />
        </div>
      </div>
    )
  }
}

export default TicketsHeader
